<template>
  <div>
    <b-row>
      <b-col md="24">
        <Input
          label="Voornaam"
          type="text"
          :error="errors.billing_first_name"
          v-model="customer.billing_first_name"
          id="customer-billing_first_name"
        />
      </b-col>
      <b-col md="24">
        <Input
          label="Achternaam"
          type="text"
          :error="errors.billing_last_name"
          v-model="customer.billing_last_name"
          id="customer-billing_last_name"
        />
      </b-col>
      <b-col md="24">
        <Input
          label="Email"
          type="email"
          :error="errors.email"
          v-model="customer.email"
          id="customer-email"
          :tooltip="'Dit veld is niet bewerkbaar i.v.m. klantsynchronisatie.'"
          :hideTooltip="customer.type === 'dealer'"
          :disabled="customer.type !== 'dealer'"
        />
      </b-col>
      <b-col md="24">
        <Input
          label="Telefoonnummer"
          type="text"
          :error="errors.billing_phone"
          v-model="customer.billing_phone"
          id="customer-billing_phone"
        />
      </b-col>
      <b-col md="24">
        <b-form-group label="Nationaliteit">
          <b-form-select v-model="customer.country">
            <b-form-select-option value="nl" selected>
              🇳🇱 Nederlands
            </b-form-select-option>
            <b-form-select-option value="be">
              🇧🇪 Belgisch
            </b-form-select-option>
            <b-form-select-option value="fr"> 🇫🇷 Frans </b-form-select-option>
            <b-form-select-option value="dk"> 🇩🇰 Deens </b-form-select-option>
            <b-form-select-option value="gb"> 🇬🇧 Engels </b-form-select-option>
            <b-form-select-option value="de"> 🇩🇪 Duits </b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col md="24">
        <b-form-group
          label="Taal"
          description="Dit is de taal die wordt gebruikt voor mails e.d."
        >
          <b-form-select
            v-model="customer.language_id"
            :options="languages"
            value-field="id"
            text-field="name"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <hr />

    <b-row>
      <b-col>
        <b-form-group
          label="Opmerking"
          class="mb-1"
          description="Deze opmerking wordt bij alle orders geplaatst"
        >
          <b-form-textarea
            v-model="customer.comments"
            placeholder="Plaats hier eventuele opmerkingen / bijzonderheden. Deze worden weergegeven op o.a. alle pakbonnen. Denk aan specifieke bezorginstructies e.d."
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    customer: {
      type: Object,
      default: () => {
        return {};
      },
    },

    errors: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      languages: [],
    };
  },

  computed: {
    ...mapGetters({
      auth: 'auth/user',
    }),
  },

  methods: {
    getLanguages() {
      this.$http.get('languages').then((response) => {
        this.languages = response.data.data;
      });
    },
  },

  mounted() {
    this.getLanguages();
  },
};
</script>
